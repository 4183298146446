import { Typography } from '@aph/ui/components/typography/typography';
import type { EventFor } from '@aph/utilities/event-for';
import type { IJumpLinkComponentFields } from '~/contentful/types';

type JumpHyperLinkProps = Pick<IJumpLinkComponentFields, 'identifier'>;

export const JumpHyperLink: React.FC<{ children?: React.ReactNode } & JumpHyperLinkProps> = ({
  identifier,
  children,
}) => {
  const onClick = (event: EventFor<'a', 'onClick'>) => {
    const element = document.getElementById(identifier);
    if (element) {
      event.preventDefault();
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Typography data-pw="jump-hyperlink" typography="link" asChild>
      <a href={`#${identifier}`} onClick={onClick} aria-hidden="true" rel="nofollow">
        {children}
      </a>
    </Typography>
  );
};

import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { container } from 'tsyringe';
import { v4 as uuidv4 } from 'uuid';
import { logger } from '~/logging';
import { Client as CustomerClient } from '~/services/generated/CustomerClient';

type useGetBonusVouchersQueryProps = {
  enabled: boolean | undefined;
};

export const useGetBonusVouchersQuery = ({ enabled }: useGetBonusVouchersQueryProps) => {
  const correlationId = useMemo(() => uuidv4(), []);

  const result = useQuery({
    queryKey: ['get-bonus-vouchers'],
    queryFn: () => {
      const customerClient = container.resolve(CustomerClient);
      return customerClient.getVouchersV4(correlationId, 'aphTinman', undefined, undefined);
    },
    retry: false,
    staleTime: Infinity,
    enabled,
  });

  const { isSuccess, error, status } = result;

  useEffect(() => {
    if (isSuccess) {
      logger.info(
        `useGetBonusVouchersQuery, Correlation ID: ${correlationId} was fetched successfully`,
      );
    }
  }, [correlationId, isSuccess]);

  useEffect(() => {
    if (error) {
      const { message } = error;
      const errorMessage = `${message || 'Något gick fel! Tyvärr kan inte denna del visas just nu'}`;
      logger.error(
        `useGetBonusVouchersQuery, Correlation ID: ${correlationId} failed to fetch | errorMessage: ${errorMessage} | status: ${status}`,
      );
    }
  }, [correlationId, error, status]);

  return result;
};

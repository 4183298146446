import React, { useCallback, useEffect, useState } from 'react';
import type { CartEvent } from '@aph/components/gtm/events/cart-gtm';
import { sendAddToCartEvent, sendRemoveFromCartEvent } from '@aph/components/gtm/events/cart-gtm';
import { createRxEventData } from '@aph/components/gtm/helpers/cart-rx';
import { useCartActions } from '~/model/cart/cart.store';
import type { LineItem } from '~/services/generated/PurchaseClient';
import { getPricePerUnit } from './get-price-per-unit';
import { MiniCartLineItemComponent } from './mini-cart-line-item.component';

export const MiniCartLineItemContainer: React.FC<{
  lineItem: LineItem;
  cartId: string;
  index: number;
  cartLoading: boolean;
}> = ({ lineItem, cartId, index, cartLoading }) => {
  const [quantity, setQuantity] = useState(0);
  const [firstUpdated, setFirstUpdated] = useState(false);
  const { updateCart, removeLineItem, getPrescriptionDetails } = useCartActions();
  const { originalPrice, discountedPrice } = getPricePerUnit({
    priceDetails: lineItem?.priceDetails || [],
    quantity,
  });

  useEffect(() => {
    if (!firstUpdated) {
      const initialQuantity = lineItem?.quantity || 0;
      setQuantity(initialQuantity);
      setFirstUpdated(true);
    }
  }, [lineItem, setQuantity, firstUpdated, setFirstUpdated, lineItem?.priceDetails]);

  useEffect(() => {
    if (!lineItem.quantity) {
      return;
    }

    if (!cartLoading) {
      setQuantity(lineItem.quantity);
    }
  }, [lineItem, setQuantity, cartLoading]);

  const handleOnChangeQuantity = useCallback(
    async (oldQuantity: number, newQuantity: number) => {
      if (lineItem.id) {
        await updateCart(
          {
            cartId,
            quantity: newQuantity,
            lineItemId: lineItem.id,
            articleCode: lineItem.articleCode || '',
          },
          { shouldThrowOnError: true },
        )
          .then(() => {
            const prescriptionDetails = lineItem.articleCode
              ? getPrescriptionDetails(lineItem.articleCode)
              : null;

            const isRx = prescriptionDetails != null;

            const eventData: CartEvent = isRx
              ? createRxEventData(prescriptionDetails, oldQuantity, newQuantity)
              : {
                  name: lineItem.title,
                  articleCode: lineItem.articleCode,
                  originalPrice,
                  discountedPrice,
                  index,
                  oldQuantity,
                  newQuantity,
                  listName: 'Produkt',
                };
            if (newQuantity > oldQuantity) {
              sendAddToCartEvent(eventData);
            } else {
              sendRemoveFromCartEvent(eventData);
            }
          })
          .catch(() => setQuantity(oldQuantity));
      }
    },
    [lineItem, getPrescriptionDetails, originalPrice, discountedPrice, index, updateCart, cartId],
  );

  const onRemoveLineItem = async () => {
    if (lineItem?.id) {
      const prescriptionDetails = lineItem.articleCode
        ? getPrescriptionDetails(lineItem.articleCode)
        : null;
      const isRx = prescriptionDetails != null;

      await removeLineItem(lineItem.id);

      const eventData: CartEvent = isRx
        ? createRxEventData(prescriptionDetails, quantity, 0)
        : {
            name: lineItem.title,
            articleCode: lineItem.articleCode,
            originalPrice,
            discountedPrice,
            index,
            oldQuantity: quantity,
            newQuantity: 0,
            listName: 'Produkt',
          };
      sendRemoveFromCartEvent(eventData);
    }
  };

  return (
    <MiniCartLineItemComponent
      title={lineItem.title || ''}
      articleUrl={lineItem.articleUrl}
      imageUrl={lineItem.imageUrl}
      image={lineItem.image}
      setQuantity={setQuantity}
      updateQuantity={handleOnChangeQuantity}
      onRemoveLineItem={onRemoveLineItem}
      quantity={quantity}
      maxQuantity={lineItem.maxQuantity}
      priceDetails={lineItem?.priceDetails}
      promotions={lineItem.hvItemProperties?.promotions}
      rxItem={!!lineItem.rxItemProperties}
    />
  );
};

import React from 'react';
import NextLink, { type LinkProps as NextLinkProps } from 'next/link';
import type { LinkProps } from '@mui/material';
import { Link } from '@mui/material';
import { urlify } from '@aph/utilities/slug';
import { useFeatureToggle } from '~/model/feature-toggle/useFeatureToggle';
import { getFeatureToggleName } from '../feature-toggles/get-feature-toggle-name';

// NextLinkProps href does not get properly set to the correct type, so have to explicitly set it here.
export type NextLinkHref = {
  pathname: string;
  query?: Record<string, string>;
};

type FeatureToggledLinkProps = {
  featureName?: string;
  forceAnchorLink?: boolean;
  href: string | NextLinkHref | undefined;
  shallow?: boolean;
  children?: React.ReactNode;
};

export type FeatureToggledNextLinkProps = FeatureToggledLinkProps &
  Omit<LinkProps, 'href'> &
  Pick<NextLinkProps, 'prefetch'>;

const baseURL = process.env.NEXT_PUBLIC_BASE_URL_WEB ?? '';
const ABSOLUTE_PATH_REGEX = /^(?:\w+:)?\/\/([^\s.]+\.\S{2})\S*$/;

const isNextLinkHref = (href: FeatureToggledLinkProps['href']): href is NextLinkHref =>
  typeof href === 'object' && 'pathname' in href;

const shouldOpenNewTab = (href: string | NextLinkHref | undefined) => {
  const link = isNextLinkHref(href) ? href.pathname : href;
  return link && ABSOLUTE_PATH_REGEX.test(link) && !link?.includes(baseURL);
};

export const FeatureToggledNextLink = React.forwardRef<
  React.ElementRef<typeof Link>,
  FeatureToggledNextLinkProps
>(({ featureName, href, prefetch, forceAnchorLink, ...props }, forwardedRef) => {
  const hrefUrl = isNextLinkHref(href) ? href.pathname : href;
  const featureToggleName = getFeatureToggleName(featureName, hrefUrl);
  const { isEnabled, feature } = useFeatureToggle(featureToggleName);

  const isUsingFeatureToggle = Boolean(feature);

  const openNewTab = shouldOpenNewTab(href);

  if (!href) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{props.children}</>;
  }

  // eslint-disable-next-line no-param-reassign
  forceAnchorLink = forceAnchorLink ?? isKnownEpiServerPath(hrefUrl);

  if (
    forceAnchorLink ||
    (isUsingFeatureToggle && !isEnabled) ||
    openNewTab ||
    isMailToLink(hrefUrl)
  ) {
    return (
      <Link
        ref={forwardedRef}
        {...props}
        href={hrefUrl}
        target={openNewTab ? '_blank' : undefined}
        rel={openNewTab ? 'noopener noreferrer' : undefined}
      />
    );
  }

  return (
    <NextLink href={urlify(hrefUrl)} passHref prefetch={prefetch} legacyBehavior>
      <Link ref={forwardedRef} {...props} />
    </NextLink>
  );
});

function isKnownEpiServerPath(url: string | undefined) {
  return (
    ['/vara-tjanster', '/hitta-apotek-hjartat'].some((path) => url?.includes(path)) &&
    !url?.match(/\/hitta-apotek-hjartat\/.*\/.+\/$/)
  );
}

function isMailToLink(url: string | undefined) {
  return url?.startsWith('mailto:');
}

import type { IconName } from '@aph/ui/components/icon/icon';

export type FooterLinkType = {
  href: string;
  title: string;
  feature?: string;
  isEpiLink?: boolean;
};

type FooterLinkListType = {
  header: FooterLinkListHeaderType;
  links: Array<FooterLinkType>;
};

export const footerLinks: FooterLinkListGroupsType = {
  popularCategories: {
    header: {
      title: 'Populära kategorier',
    },
    links: [
      {
        title: 'Ansiktsvård',
        href: '/produkter/hudvard/ansiktsvard/',
      },
      {
        title: 'Barn & föräldrar',
        href: '/produkter/barn-foraldrar/',
      },
      {
        title: 'Kosttillskott & vitaminer',
        href: '/produkter/kosttillskott-vitaminer/',
      },
      {
        title: 'Förkylning',
        href: '/produkter/forkylning-nasa/forkylning/',
      },
    ],
  },
  services: {
    header: {
      title: 'Våra tjänster',
    },
    links: [
      {
        title: 'Se Hjärtats tjänster i apoteken',
        href: '/vara-tjanster/',
        isEpiLink: true,
      },
    ],
  },
  about: {
    header: {
      title: 'Om oss',
    },
    links: [
      {
        title: 'För leverantörer',
        href: '/om-oss/kontakta-supportkontoret/for-leverantorer/',
        isEpiLink: true,
      },
      { title: 'Hållbarhet', href: '/om-oss/hallbarhet/', isEpiLink: true },
      {
        title: 'Karriär & lediga tjänster',
        href: 'https://karriar.apotekhjartat.se/se/sv',
      },
      {
        title: 'Kontakt supportkontoret',
        href: '/om-oss/kontakta-supportkontoret/',
        isEpiLink: true,
      },
      {
        title: 'Just nu på Hjärtat',
        href: '/om-oss/just-nu-pa-hjartat/',
        isEpiLink: true,
      },
      {
        title: 'Press och bildbank',
        href: '/om-oss/pressrum/pressmeddelanden/',
        isEpiLink: true,
      },
    ],
  },
  help: {
    header: {
      title: 'Support',
    },
    links: [
      {
        title: 'Hitta apotek',
        href: '/hitta-apotek-hjartat/',
        isEpiLink: true,
      },
      {
        title: 'Kontakta oss',
        href: '/kundservice/kontakta-oss/',
      },
      {
        title: 'Vanliga frågor',
        href: '/kundservice/vanliga-fragor/',
      },
      {
        title: 'Frakt & leverans',
        href: '/kundservice/frakt-leverans/',
      },
      {
        title: 'Betalning',
        href: '/mina-recept/sa-funkar-det/delbetalning-pa-apotek-hjartat/',
        isEpiLink: true,
      },
      {
        title: 'Förskrivare',
        href: '/kundservice/forskrivare/',
      },
      {
        title: 'Receptfrågor',
        href: '/mina-recept/sa-funkar-det/hamta-ut-dina-recept-online/',
        isEpiLink: true,
      },
    ],
  },
};

export const footerShortcutLinks: FooterLinkType[] = [
  { title: 'Hem', href: '/' },
  { title: 'Rådgivning', href: '/tips-och-rad/' },
  { title: 'Klubb Hjärtat', href: '/klubb-hjartat/mina-sidor/' },
  { title: 'Hitta apotek', href: '/hitta-apotek-hjartat/' },
  { title: 'Kontakta oss', href: '/kundservice/kontakta-oss/' },
];

export const footerLegalLinks: FooterLinkType[] = [
  { title: 'Anpassa cookieinställningar', href: '/cookieinstallningar/' },
  { title: 'Information om cookies', href: '/om-oss/information-om-cookies/', isEpiLink: true },
  { title: 'Köpvillkor', href: '/kundservice/kopvillkor/' },
  {
    title: 'Personuppgiftsbehandling',
    href: '/om-oss/var-personuppgiftsbehandling/',
    isEpiLink: true,
  },
];

export const footerSocialLogosLinks: FooterSocialLogosLinkType[] = [
  {
    href: 'https://instagram.com/apotekhjartat',
    icon: 'Instagram',
    ariaLabel: "Besök Apotek Hjärtat's Instagram, öppnas i ny flik.",
  },
  {
    href: 'https://www.youtube.com/user/apotekhjartat',
    icon: 'YouTube',
    ariaLabel: "Besök Apotek Hjärtat's Youtube kanal, öppnas i ny flik.",
  },
  {
    href: 'https://www.facebook.com/apotekhjartat?fref=ts',
    icon: 'Facebook',
    ariaLabel: "Besök Apotek Hjärtat's Facebook, öppnas i ny flik.",
  },
  {
    href: 'https://www.linkedin.com/company/apotekhjartat/',
    icon: 'LinkedIn',
    ariaLabel: "Besök Apotek Hjärtat's Linkedin, öppnas i ny flik.",
  },
];

export type FooterLinkListHeaderType = {
  title: string;
};

type FooterLinkListGroupsType = {
  popularCategories: FooterLinkListType;
  services: FooterLinkListType;
  about: FooterLinkListType;
  help: FooterLinkListType;
};

export type FooterSocialLogosLinkType = {
  href: string;
  icon: IconName;
  ariaLabel?: string;
};
